/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendAndReceive, sendOnly } from '@api/utils'
import { IdObject } from '@api/endpoints/types'
import {
  EnergyClass,
  EnergyClassId,
  EnergyClassUpdate,
  FormOfOwnership,
  FormOfOwnershipId,
  FormOfOwnershipUpdate,
  ProjectSettings,
  ProjectSettingsUpdate,
  PropertyType,
  PropertyTypeId,
  PropertyTypeUpdate
} from '.'

const projectSettingsUrl = `${apiBaseUrl}/settings/project`
const projectSettingsEnergyClassesUrl = `${projectSettingsUrl}/energy_class`
const projectSettingsEnergyClassUrl = (id: EnergyClassId) => `${projectSettingsEnergyClassesUrl}/${id}`
const projectSettingsFormsOfOwnershipUrl = `${projectSettingsUrl}/form_of_ownership`
const projectSettingsFormOfOwnershipUrl = (id: FormOfOwnershipId) => `${projectSettingsFormsOfOwnershipUrl}/${id}`
const projectSettingsPropertyTypesUrl = `${projectSettingsUrl}/property_type`
const projectSettingsPropertyTypeUrl = (id: PropertyTypeId) => `${projectSettingsPropertyTypesUrl}/${id}`

const queryKeys = {
  getSettings: ['getSettings'],
  getEnergyClasses: ['getEnergyClasses'],
  getFormsOfOwnership: ['getFormsOfOwnership'],
  getPropertyTypes: ['getPropertyTypes']
}

export const useGetSettings = (opts?: ApiQueryOpts<ProjectSettings>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: projectSettingsUrl,
        responseSchema: ProjectSettings
      }),
    queryKey: queryKeys.getSettings,
    ...opts
  })

export const useUpdateSettings = (opts?: ApiMutationOpts<void, ProjectSettingsUpdate>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'put',
        url: projectSettingsUrl,
        requestSchema: ProjectSettingsUpdate,
        data
      }),
    invalidateQueries: [queryKeys.getSettings],
    ...opts
  })

export const useGetEnergyClasses = (opts?: ApiQueryOpts<ReadonlyArray<EnergyClass>>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: projectSettingsEnergyClassesUrl,
        responseSchema: EnergyClass.array()
      }),
    queryKey: queryKeys.getEnergyClasses,
    ...opts
  })

export const useCreateEnergyClass = (opts?: ApiMutationOpts<IdObject, EnergyClassUpdate>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: projectSettingsEnergyClassesUrl,
        requestSchema: EnergyClassUpdate,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getEnergyClasses],
    ...opts
  })

type WithEnergyClassId = {
  id: EnergyClassId
}

export const useUpdateEnergyClass = (opts?: ApiMutationOpts<void, EnergyClassUpdate & WithEnergyClassId>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: projectSettingsEnergyClassUrl(id),
        requestSchema: EnergyClassUpdate,
        data
      }),
    invalidateQueries: [queryKeys.getEnergyClasses],
    ...opts
  })

export const useDeleteEnergyClass = (opts?: ApiMutationOpts<void, EnergyClassId>) =>
  useApiMutation({
    mutationFn: (id) =>
      callOnly({
        method: 'delete',
        url: projectSettingsEnergyClassUrl(id)
      }),
    invalidateQueries: [queryKeys.getEnergyClasses],
    ...opts
  })

export const useGetFormsOfOwnership = (opts?: ApiQueryOpts<ReadonlyArray<FormOfOwnership>>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: projectSettingsFormsOfOwnershipUrl,
        responseSchema: FormOfOwnership.array()
      }),
    queryKey: queryKeys.getFormsOfOwnership,
    ...opts
  })

export const useCreateFormOfOwnership = (opts?: ApiMutationOpts<IdObject, FormOfOwnershipUpdate>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: projectSettingsFormsOfOwnershipUrl,
        requestSchema: FormOfOwnershipUpdate,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getFormsOfOwnership],
    ...opts
  })

type WithFormOfOwnershipId = {
  id: FormOfOwnershipId
}

export const useUpdateFormOfOwnership = (opts?: ApiMutationOpts<void, FormOfOwnershipUpdate & WithFormOfOwnershipId>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: projectSettingsFormOfOwnershipUrl(id),
        requestSchema: FormOfOwnershipUpdate,
        data
      }),
    invalidateQueries: [queryKeys.getFormsOfOwnership],
    ...opts
  })

export const useDeleteFormOfOwnership = (opts?: ApiMutationOpts<void, FormOfOwnershipId>) =>
  useApiMutation({
    mutationFn: (id) =>
      callOnly({
        method: 'delete',
        url: projectSettingsFormOfOwnershipUrl(id)
      }),
    invalidateQueries: [queryKeys.getFormsOfOwnership],
    ...opts
  })

export const useGetPropertyTypes = (opts?: ApiQueryOpts<ReadonlyArray<PropertyType>>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: projectSettingsPropertyTypesUrl,
        responseSchema: PropertyType.array()
      }),
    queryKey: queryKeys.getPropertyTypes,
    ...opts
  })

export const useCreatePropertyType = (opts?: ApiMutationOpts<IdObject, PropertyTypeUpdate>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: projectSettingsPropertyTypesUrl,
        requestSchema: PropertyTypeUpdate,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [queryKeys.getPropertyTypes],
    ...opts
  })

type WithPropertyTypeId = {
  id: PropertyTypeId
}

export const useUpdatePropertyType = (opts?: ApiMutationOpts<void, PropertyTypeUpdate & WithPropertyTypeId>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: projectSettingsPropertyTypeUrl(id),
        requestSchema: PropertyTypeUpdate,
        data
      }),
    invalidateQueries: [queryKeys.getPropertyTypes],
    ...opts
  })

export const useDeletePropertyType = (opts?: ApiMutationOpts<void, PropertyTypeId>) =>
  useApiMutation({
    mutationFn: (id) =>
      callOnly({
        method: 'delete',
        url: projectSettingsPropertyTypeUrl(id)
      }),
    invalidateQueries: [queryKeys.getPropertyTypes],
    ...opts
  })
